import React from "react";
import "./_about.scss";
import { aboutImg, campaignIntro } from "../../assets";

function AboutUs() {
  return (
    <div name="info">
      <section className="info-sec">
        <h1>
          What is <span>Deuces?</span>
        </h1>

        <div className="inner">
          <div className="left">
            <p>
              Deuces is an email marketing platform that automates the creation
              of highly personalized email content to resonate with each
              individual recipient. It simplifies the process of crafting,
              scheduling, and analyzing email campaigns at scale, ensuring that
              every communication is strategically optimized for engagement and
              conversion.
            </p>
          </div>

          <div className="right">
            <video muted autoPlay loop poster={aboutImg}>
              <source src={campaignIntro} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
