import React, { useEffect, useState } from 'react';
import { ancharTop } from '../assets';

const PrivacyPolicy = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };
        window.scrollTo(0, 0);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <>
            <div className='d-flex v-center h-center'>
                <div className="container privacy-policy">
                    <h1 className="display-4 mb-5 text-center">Privacy Policy</h1>
                    <p className="text-muted text-center fw-bold">Effective Date: August 20, 2024</p>

                    <p className="lead mt-5">At Deuces, we prioritize your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our services.</p>

                    <h2 className="mt-5">Information We Collect</h2>
                    <p>We collect various types of information in connection with the services we provide, including:</p>
                    <ul>
                        <li><strong>Personal Information:</strong> When you register on our site, sign up for our newsletter, or otherwise interact with our services, we may collect information such as your name, email address, and contact details.</li>
                        <li><strong>Usage Data:</strong> We collect information automatically when you use our services, such as your IP address, browser type, and pages visited.</li>
                        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to track the activity on our service and hold certain information.</li>
                    </ul>

                    <h2 className="mt-5">How We Use Your Information</h2>
                    <p>We use the information we collect for various purposes, including to:</p>
                    <ul>
                        <li><strong>Provide and Maintain our Services:</strong> We use your information to ensure the efficient operation of our services, including account creation and customer support.</li>
                        <li><strong>Communicate with You:</strong> We may use your information to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you.</li>
                        <li><strong>Improve Our Services:</strong> We analyze the usage data to enhance and develop our offerings, ensuring a better user experience.</li>
                        <li><strong>Legal and Compliance:</strong> We may use your data to comply with applicable laws, regulations, and legal processes.</li>
                    </ul>

                    <h2 className="mt-5">Sharing Your Information</h2>
                    <p>Your information may be shared under the following circumstances:</p>
                    <ul>
                        <li><strong>With Your Consent:</strong> We will share your personal information with third parties when we have your explicit consent to do so.</li>
                        <li><strong>For Legal Reasons:</strong> We may share your data to comply with legal obligations or to protect the rights, property, or safety of Deuces.ai, our users, or the public.</li>
                        <li><strong>Business Transfers:</strong> If Deuces.ai is involved in a merger, acquisition, or asset sale, your personal information may be transferred.</li>
                    </ul>

                    <h2 className="mt-5">Security of Your Information</h2>
                    <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

                    <h2 className="mt-5">Your Privacy Rights</h2>
                    <p>You have certain rights regarding the information we hold about you, including:</p>
                    <ul>
                        <li><strong>Access and Update:</strong> You can request access to or updates to your personal information.</li>
                        <li><strong>Deletion:</strong> You may request the deletion of your personal information, subject to certain legal obligations.</li>
                        <li><strong>Opt-out:</strong> You may opt-out of receiving our marketing communications at any time by following the unsubscribe link in our emails.</li>
                    </ul>

                    <h2 className="mt-5">Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

                    <h2 className="mt-5">Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please <a href="/contact">Contact us</a>.</p>
                </div>
            </div>

            {showButton && (
                <img
                    src={ancharTop}
                    alt=""
                    className="scrollToTop"
                    onClick={() => window.scrollTo({ top: 0 })}
                />
            )}
        </>
    );
}

export default PrivacyPolicy;
